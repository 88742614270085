import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/hospital/signup',
		name: 'hospHignup',
		component: () => import('../views/hospital/Signup.vue'),
	},
    {
		path: '/hospital/login',
		name: 'hospLogin',
		component: () => import('../views/hospital/Login.vue'),
	},
    {
		path: '/hospital/fill_sheet',
		name: 'hospFS',
		component: () => import('../views/hospital/Setup.vue'),
	},
	{
		path: '/hospital/accounts',
		name: 'hospAs',
		component: () => import('../views/hospital/AccountConfig.vue'),
	},
	{
		path: '/hospital/contact/:id',
		name: 'hospContact',
		component: () => import('../views/hospital/Contact.vue'),
	},
	{
		path: '/hospital/apply_list',
		name: 'hospList',
		component: () => import('../views/hospital/ApplyList.vue'),
	},
	{
		path: '/hospital/apply_new',
		name: 'ApplyNew',
		component: () => import('../views/hospital/ApplyNew.vue'),
	},
	{
		path: '/hospital/apply_edit/:id',
		name: 'ApplyNewEdit',
		component: () => import('../views/hospital/ApplyNew.vue'),
	},
	{
		path: '/hospital/apply_copy/:id',
		name: 'ApplyNewCopy',
		component: () => import('../views/hospital/ApplyNew.vue'),
	},
	{
		path: '/hospital/interview_list',
		name: 'InterviewList',
		component: () => import('../views/hospital/InterviewList.vue'),
	},
	{
		path: '/hospital/interview_new',
		name: 'InterviewNew',
		component: () => import('../views/hospital/InterviewNew.vue'),
	},
	{
		path: '/hospital/interview_edit/:id',
		name: 'InterviewNewEdit',
		component: () => import('../views/hospital/InterviewNew.vue'),
	},
	{
		path: '/hospital/subscribe',
		name: 'SubscribeSubscription',
		component: () => import('../views/hospital/subscribe/Subscription.vue'),
	},
	{
		path: '/hospital/subscribe/new',
		name: 'SubscribeNew',
		component: () => import('../views/hospital/subscribe/New.vue'),
	},
	{
		path: '/hospital/subscribe/add_payment',
		name: 'AddPayment',
		component: () => import('../views/hospital/subscribe/AddPayment.vue'),
	},
	{
		path: '/hospital/subscribe/remove',
		name: 'SubscribeRemove',
		component: () => import('../views/hospital/subscribe/Remove.vue'),
	},
	{
		path: '/hospital/subscribe/customer_portal',
		name: ' CustomerPortal',
		component: () => import('../views/hospital/subscribe/CustomerPortal.vue'),
	},
	{
		path: '/hospital/archive',
		name: 'Archive',
		component: () => import('../views/hospital/subscribe/Remove.vue'),
	},
]

export default routes
