import { createRouter, createWebHistory } from 'vue-router'
import studentRouter from './student'
import hospitalRouter from './hospital'
import adminRouter from './admin'
import { RouteRecordRaw } from 'vue-router'

const routesCommon: Array<RouteRecordRaw> = [
	{
		path: '/apply/:id',
		name: 'ApplyIndv',
		component: () => import('../views/ApplyIndv.vue'),
	},
	{
		path: '/h/:id',
		name: 'HospitalIndv',
		component: () => import('../views/HospitalIndv.vue'),
	},
	{
		path: '/search',
		name: 'Search',
		component: () => import('../views/HospitalSearch.vue'),
	},
	{
		path: '/review',
		name: 'ReviewList',
		component: () => import('../views/ReviewList.vue')
	},
	{
		path: '/sitemap',
		name: 'Sitemap',
		component: () => import('../views/Sitemap.vue')
	},
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue'),
	}
]

const routes = routesCommon.concat(studentRouter).concat(hospitalRouter).concat(adminRouter)


const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
