import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/my/review/edit',
		name: 'reviews',
		component: () => import('../views/student/ReviewEdit.vue'),
	},
]

export default routes
