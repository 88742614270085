import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
		path: '/admin/login',
		name: 'adminLogin',
		component: () => import('../views/admin/Login.vue'),
	},
    {
		path: '/admin/apply',
		name: 'adminApply',
		component: () => import('../views/admin/Apply.vue'),
	},
    {
		path: '/admin/review',
		name: 'adminReview',
		component: () => import('../views/admin/Review.vue'),
	},
	{
		path: '/admin/student',
		name: 'StudentList',
		component: () => import('../views/admin/Student.vue'),
	},
	{
		path: '/admin/hospital',
		name: 'HospitalList',
		component: () => import('../views/admin/Hospital.vue'),
	},
	{
		path: '/admin/hospital/add',
		name: 'HospitalAdd',
		component: () => import('../views/admin/HospitalAdd.vue'),
	},
	{
		path: '/admin/accounts',
		name: 'AdminAccount',
		component: () => import('../views/admin/AccountConfig.vue'),
	},
	{
		path: '/admin/invoice/:id',
		name: 'Invoice',
		component: () => import('../views/admin/Invoice.vue'),
	},
	{
		path: '/admin/invoice_print/:id',
		name: 'InvoicPrint',
		component: () => import('../views/admin/InvoicePrint.vue'),
	}
]

export default routes
